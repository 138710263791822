import { Application, FormType, MutationGroup, MutationType, OfferFase, RequestType } from "../typings/enums"
import { ClauseCode, DownloadForm, FormContentTexts, Mutation, PaperForm, PolicyData } from "../typings/interfaces"
import { documents } from "../data/documentlist"
import { contactFormSubjects } from "../data/contactformsubjects"
import { ProductCode } from "leven-shared-components/typings/products"
import { TaskType } from "leven-shared-components/typings/tasktypes"
import { isUndefined } from "lodash-es"

type FormContent = {
	[key in keyof typeof FormType]: ApplicationFormContentTexts
}

type ApplicationFormContentTexts = {
	[key in keyof typeof Application]: FormContentTexts
}

const cockpitHuidigeReactietermijnenLink = `<a href='https://www.asrcockpit.nl/nieuws-en-acties/reactietermijnen' target='_blank'>huidige reactietermijnen</a>`
const basicDataChangedText = "<br><br>U heeft uw e-mailadres of telefoonnummer gewijzigd, deze hebben wij voor u aangepast. U ontvangt hierover een bevestiging per e-mail."

const formContent: FormContent = {
	[FormType.CONTACT]: {
		[Application.SELFSERVICE]: {
			messageLabel: "Uw vraag",
			placeHolder: "Uw vraag kunt u hier kwijt.",
			send: "Bedankt voor uw vraag. U krijgt zo snel mogelijk een reactie van ons. Op het dashboard onder 'Actueel' vindt u de huidige reactietermijnen.<br><br>Met vriendelijke groet,<br><br>Klantteam Leven",
			sendPlus: `Bedankt voor uw vraag. U krijgt zo snel mogelijk een reactie van ons. Op het dashboard onder 'Actueel' vindt u de huidige reactietermijnen.${basicDataChangedText}<br><br>Met vriendelijke groet,<br><br>Klantteam Leven`
		},
		[Application.EXTRANET]: {
			messageLabel: "Uw vraag",
			placeHolder: "Uw vraag kunt u hier kwijt.",
			send: `Bedankt voor uw vraag. U krijgt zo snel mogelijk een reactie van ons. Op de informatiepagina bij actueel vindt u de <span>${cockpitHuidigeReactietermijnenLink}</span>.<br><br>Met vriendelijke groet,<br><br>Klantteam Leven`,
			sendPlus: ""
		}
	},
	[FormType.POLICY_CHANGE]: {
		[Application.SELFSERVICE]: {
			messageLabel: "Opmerkingen",
			placeHolder: "Extra op en/of aanmerkingen kunt u hier kwijt.",
			send: "We gaan uw wijzigingsverzoek beoordelen. U krijgt zo snel mogelijk een reactie van ons. Op het dashboard onder 'Actueel' vindt u de huidige reactietermijnen.<br><br>Het kan zijn dat wij nog aanvullende informatie nodig hebben, hierover ontvangt u dan een bericht van ons.<br><br>Met vriendelijke groet,<br><br>Klantteam Leven",
			sendPlus: `We gaan uw wijzigingsverzoek beoordelen. U krijgt zo snel mogelijk een reactie van ons. Op het dashboard onder 'Actueel' vindt u de huidige reactietermijnen.<br><br>Het kan zijn dat wij nog aanvullende informatie nodig hebben, hierover ontvangt u dan een bericht van ons.${basicDataChangedText}<br><br>Met vriendelijke groet,<br><br>Klantteam Leven`
		},
		[Application.EXTRANET]: {
			messageLabel: "Opmerkingen",
			placeHolder: "Extra op en/of aanmerkingen kunt u hier kwijt.",
			send: `We gaan uw wijzigingsverzoek beoordelen. U krijgt zo snel mogelijk een reactie van ons. Op de informatiepagina bij actueel vindt u de <span>${cockpitHuidigeReactietermijnenLink}</span>.<br>Het kan zijn dat wij nog aanvullende informatie nodig hebben, hierover ontvangt u dan een bericht van ons.<br><br>Met vriendelijke groet,<br><br>Klantteam Leven`,
			sendPlus: ""
		}
	}
}

const bankAccountForm: PaperForm = {
	title: "Doorlopende machtiging",
	url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/50364.pdf"
}

const premiumContributorForm: PaperForm = {
	title: "Doorlopende machtiging",
	url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/50364.pdf"
}

const policyHolderForm: PaperForm = {
	title: "Wijziging verzekeringnemer",
	url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/55940.pdf"
}

const beneficiaryForm: PaperForm = {
	title: "Aanvraag wijziging begunstiging",
	url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/55914.pdf"
}

const investmentChoiceForm: PaperForm = {
	title: "Switch en premieherbestemming fondsen",
	url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/55730.pdf"
}

const nonSmokersDiscountForm: PaperForm = {
	title: "Niet-rokersverklaring",
	url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/53862.pdf"
}

const determineTaxStatusForm: PaperForm = {
	title: "Bepalen belastingstatus",
	url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/55636.pdf"
}

const payoutBeneficiaryConsentForms: Array<DownloadForm> = [
	{
		clauses: [ClauseCode.LYF_BHW, ClauseCode.LYF_IB2001, ClauseCode.ODGRSV_NW, ClauseCode.STW_IB2001],
		category: "Lijfrente nieuw regime",
		title: "Uitkeringsformulier",
		url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/55643.pdf&_ga=2.23976120.349287110.1645698742-1277898746.1559829743"
	},
	{
		clauses: [ClauseCode.KAP_LYF, ClauseCode.STKG_WINST, ClauseCode.ODGRSV_FOR],
		category: "Lijfrente oud regime",
		title: "Uitkeringsformulier",
		url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/53759.pdf&_ga=2.191934504.349287110.1645698742-1277898746.1559829743"
	},
	{
		clauses: [ClauseCode.GDHDRK_NW, ClauseCode.GDHDRK],
		category: "Gouden handdruk",
		title: "Uitkeringsformulier",
		url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/55650.pdf&_ga=2.258921608.349287110.1645698742-1277898746.1559829743"
	},
	{
		clauses: [ClauseCode.KAP_BOX1, ClauseCode.KAP_Box3,	ClauseCode.KAP_pBHW, ClauseCode.KAP_BHW_BV, ClauseCode.KAP_BHW, ClauseCode.KEW],
		category: "Kapitaalverzekering + KEW",
		title: "Uitkeringsformulier",
		url: "https://www.asr.nl/asr/api/asrnl/pod/getpdf?uri=/POD/r/Pdf/55645.pdf&_ga=2.258921608.349287110.1645698742-1277898746.1559829743"
	}
]

const getPayoutBeneficiaryConsentForm = (clauseCode: ClauseCode) => {
	return payoutBeneficiaryConsentForms.find(form => form.clauses.includes(clauseCode))
}

const contactForm: PaperForm = {
	url: "https://www.asr.nl/contactformulier?1_v_Onderwerp=Levensverzekering&1_v_SubonderwerpLevensverzekering=RekeningnummerWijzigen",
	title: "Contactformulier"
}

const advisorText = "Vraag uw adviseur wat de gevolgen zijn. Hij kent uw situatie en geeft advies op maat."
const consequencesText = "Deze wijziging kan grote gevolgen hebben."
const linkOtherMutation = "<a id='linkToOtherMutation' href='#'>Andere wijziging</a>"
const bankAccountLink = `<a href='${bankAccountForm.url}' target='_blank'>${bankAccountForm.title} (pdf)</a>`
const premiumContributorFormLink = `<a id='linkToPremiumContributorForm' href='${premiumContributorForm.url}' target='_blank'>${premiumContributorForm.title} (pdf)</a>`
const requestIsFinalText = "Na ontvangst van het door u ondertekende formulier is het verzoek definitief en kan het niet meer ongedaan worden gemaakt."
const changeBankAccountOrPaymentLink = `<a id='linkToContactForm' href='${contactForm.url}' target='_blank'>${contactForm.title}</a>`
const introPremiumContributor = `<br><strong>Stap 1:</strong> Download het formulier "${premiumContributorForm.title}".<span class='mutation-buttons'>${premiumContributorFormLink}</span><strong>Stap 2:</strong> De nieuwe premiebetaler maakt eenmalig de premie over naar<br>NL15 ABNA 0240 5767 80 ten name van ASR Betalingscentrum B.V.<br>Vermeld daarbij: 'nieuwe SEPA-machtiging' en het polisnummer. Hierdoor weten we zeker dat we het geld straks van de goede rekening afschrijven.<br><br><strong>Stap 3:</strong> Mail het ingevulde en ondertekende formulier "${premiumContributorForm.title}".<br><br><div class="grey-box info-box premium-contributor"><strong>Let op:</strong> Stuur met het formulier ook altijd de volgende documenten mee:<ul class="pl"><li>Een duidelijk leesbare kopie van een geldig identiteitsbewijs van de nieuwe premiebetaler. In verband met de privacywetgeving vragen wij u om de volgende gegevens af te schermen: het BSN (ook in de cijferreeks onderaan) en de pasfoto.</li><li>Een duidelijk leesbare kopie of schermafdruk van een recent bankafschrift van de nieuwe premiebetaler als extra controle van de identiteit. Het bankafschrift moet van een betaalrekening zijn en mag niet ouder zijn dan drie maanden. Deze gegevens moeten goed leesbaar zijn:<ul><li>de naam van de rekeninghouder</li><li>het adres (straatnaam, postcode en woonplaats) van de rekeninghouder</li><li>het IBAN rekeningnummer</li><li>de datum (dd-mm-jjjj) van het bankafschrift</li></ul></li></ul></div><span class='mutation-buttons'><a id="mailBankAccountForm" href="mailto:debiteurenbeheer.leven@asr.nl" target="_blank">Mail het formulier</a></span>`
const investmentChoiceGuaranteeWarning = "<strong>Let op! U heeft een beleggingsverzekering met garantiekapitaal</strong><br/>Een (gedeeltelijke) switch en/of premieherbestemming naar een ander fonds heeft mogelijk nadelige gevolgen voor uw garantiekapitaal. Dit kapitaal kan lager worden of zelfs helemaal vervallen. De voorwaarden voor garantie staan beschreven op de polis en/of in de algemene voorwaarden.<br/><br/>"

const investmentChoiceConditionalIntro = (policyData: PolicyData): string | undefined => {
	return policyData.funds.some(fund => fund.guaranteeValue) ? investmentChoiceGuaranteeWarning : undefined
}

const mutations: Array<Mutation> = [
	{
		mutationType: MutationType.CUSTOMERNAME,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.OTHERPAGE,
		subject: "Naam",
		title: "Naam wijzigen",
		path: "naam-wijzigen",
		[Application.SELFSERVICE]: {
			intro: `Uw naam wijzigen? Ga naar: <span class='mutation-buttons'><a id='linkToProfilePage' href='#'>Persoonlijke gegevens</a></span> Naam van andere persoon wijzigen? Ga naar: <span class='mutation-buttons'>${linkOtherMutation}</span>`,
			active: true
		},
		[Application.EXTRANET]: {
			intro: `<ul><li>Voor de verzekeringnemer(s) wijzigt u de naam via het Klantdossier.</li><li>Gaat het om een andere persoon op de polis? Kies dan voor "Andere wijziging".</li></ul><span class='mutation-buttons'>${linkOtherMutation}</span>`,
			active: true
		}
	},
	{
		mutationType: MutationType.ADDRESS,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.OTHERPAGE,
		subject: "Adres",
		title: "Adres wijzigen",
		path: "adres-wijzigen",
		[Application.SELFSERVICE]: {
			intro: `Voor uzelf? Ga naar: <span class='mutation-buttons'><a id='linkToProfilePage' href='#'>Persoonlijke gegevens</a></span> Voor iemand anders op de polis? Ga naar: <span class='mutation-buttons'>${linkOtherMutation}</span>`,
			active: true
		},
		[Application.EXTRANET]: {
			intro: `<ul><li>Voor de verzekeringnemer(s) wijzigt u het adres via het Klantdossier.</li><li>Gaat het om een andere persoon op de polis? Kies dan voor "Andere wijziging".</li></ul><span class='mutation-buttons'>${linkOtherMutation}</span>`,
			active: true
		}
	},
	{
		mutationType: MutationType.CONTACTDETAILS,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.OTHERPAGE,
		subject: "Contactgegevens",
		title: "Contactgegevens wijzigen",
		path: "contactgegevens-wijzigen",
		[Application.SELFSERVICE]: {
			intro: "Op uw profiel pagina kunt u uw e-mailadres en telefoonnummer aanpassen. <span class='mutation-buttons'><a id='linkToProfilePage' href='#'>Persoonlijke gegevens</a></span>",
			active: true
		},
		[Application.EXTRANET]: {
			intro: "",
			active: false
		}
	},
	{
		mutationType: MutationType.BANKACCOUNT,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.OTHERPAGE,
		subject: "Rekeningnummer of betaalwijze",
		title: "Rekeningnummer of betaalwijze wijzigen",
		path: "rekeningnummer-wijzigen",
		excludeForProducts: [
			ProductCode.DIL,
			ProductCode.EXP
		],
		[Application.SELFSERVICE]: {
			intro: `Betaalt u de premie voor uw verzekering al via automatische incasso, maar wilt u de premie voortaan van een ander rekeningnummer laten afschrijven?<br><br>Of betaalt u de premie voor uw verzekering nog niet via automatische incasso, maar wilt u dat voortaan wel?<br><br>Dan regelt u dat in 4 stappen:<br><br><strong>Stap 1:</strong> Ga naar het contactformulier.<br><div class="info-box bank-account"><ul><li>Het onderwerp en het subonderwerp zijn al ingevuld. Die hoeft u niet meer aan te passen.</li><li>Bij 'Uw vraag' geeft u aan dat u uw rekeningnummer voor de premiebetaling wilt wijzigen of dat u uw premie voortaan automatisch wilt betalen.</li><li>Daarna vult u uw gegevens in en klikt u op versturen.</li></ul></div><br><strong>Stap 2:</strong> U ontvangt binnen 10 werkdagen een e-mail van ons met een link en instructies.<br><br><strong>Stap 3:</strong> U gebruikt de link om ons met internetbankieren een machtiging te geven.<br><br><strong>Stap 4:</strong> U ontvangt een bevestiging van uw bank dat de machtiging is afgegeven.<br><br><span class='mutation-buttons'>${changeBankAccountOrPaymentLink}</span>`,
			active: true
		},
		[Application.EXTRANET]: {
			intro: `<strong>Stap 1:</strong> Download het formulier "${bankAccountForm.title}".<span class='mutation-buttons'>${bankAccountLink}</span><strong>Stap 2:</strong> Uw klant maakt met zijn nieuwe bankrekeningnummer eenmalig de premie over naar NL15 ABNA 0240 5767 80 ten name van ASR Betalingscentrum B.V. In de omschrijving moet komen te staan: 'nieuwe SEPA-machtiging' en het polisnummer. Hierdoor weten wij zeker dat we het geld straks van de goede rekening afschrijven.<br><br><strong>Stap 3:</strong> Mail het ingevulde en ondertekende formulier "${bankAccountForm.title}" naar: <a href="mailto:debiteurenbeheer.leven@asr.nl">debiteurenbeheer.leven@asr.nl</a>`,
			active: true
		}
	},
	{
		mutationType: MutationType.DETERMINE_TAX_STATUS,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.PDFUPLOAD,
		taskTypeRequest: TaskType.BEPALEN_BELASTINGSTATUS,
		subject: "Bepalen belastingstatus",
		title: "Bepalen belastingstatus",
		path: "bepalen-belastingstatus",
		excludeForCountries: ["NL"],
		requiredDocuments: [documents.determineTaxStatus],
		[Application.SELFSERVICE]: {
			intro: "Upload uw ingevulde en ondertekende formulier \"Bepalen belastingstatus\" (pdf).",
			paperForm: determineTaxStatusForm,
			paperFormUploadOnly: true,
			active: true
		},
		[Application.EXTRANET]: {
			intro: "Upload uw ingevulde en ondertekende formulier \"Bepalen belastingstatus\" (pdf).",
			paperForm: determineTaxStatusForm,
			paperFormUploadOnly: true,
			active: true
		}
	},
	{
		mutationType: MutationType.CREDENTIALS_MYASR,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.OTHERPAGE,
		subject: "Inloggegevens voor a.s.r. account",
		title: "Inloggegevens voor a.s.r. account wijzigen",
		path: "inloggegevens-voor-mijnasr-wijzigen",
		[Application.SELFSERVICE]: {
			intro: "Uw inloggegevens wijzigen? Ga naar:<span class='mutation-buttons'><a id='linkToProfilePage' href='#'>Persoonlijke gegevens</a></span>",
			active: true
		},
		[Application.EXTRANET]: {
			intro: "",
			active: false
		}
	},
	{
		mutationType: MutationType.COMMUNICATION_PREFERENCE,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.OTHERPAGE,
		subject: "Contact via post of email?",
		title: "Contact via post of email?",
		path: "communicatievoorkeur-wijzigen",
		[Application.SELFSERVICE]: {
			intro: "Hoe wilt u dat wij u benaderen? Verander uw voorkeur via: <span class='mutation-buttons'><a id='linkToProfilePage' href='#Communicatievoorkeur'>Profiel pagina</a></span>",
			active: true
		},
		[Application.EXTRANET]: {
			intro: "",
			active: false
		}
	},
	{
		mutationType: MutationType.COLLECTION_VIA_ASR,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.DIGITAL,
		subject: "Incasso via a.s.r.",
		title: "Incasso via a.s.r.",
		path: "incasso-via-asr-wijzigen",
		taskTypeRequest: TaskType.WIJZ_INCASSO,
		excludeForProducts: [
			ProductCode.DIL,
			ProductCode.EXP
		],
		requiredDocuments: [
			documents.signedRequestExtranet
		],
		[Application.SELFSERVICE]: {
			intro: "",
			active: false
		},
		[Application.EXTRANET]: {
			intro: "Wijzig de incasso van tussenpersoon-incasso naar maatschappij-incasso.",
			active: true
		}
	},
	{
		mutationType: MutationType.DECEASE,
		mutationGroup: MutationGroup.ADMINISTRATION,
		requestType: RequestType.OTHERPAGE,
		subject: "Overlijden doorgeven",
		title: "Overlijden doorgeven",
		path: "overlijden-doorgeven",
		[Application.SELFSERVICE]: {
			intro: "Is er iemand overleden? Gecondoleerd. Het overlijden kunt u het beste melden bij onze:<span class='mutation-buttons'><a id='linkToRelativesDesk' href='#'>Nabestaandendesk</a></span>",
			active: true
		},
		[Application.EXTRANET]: {
			intro: `<ul><li>Is een verzekeringsnemer overleden? Geef dit door via het Klantdossier.</li><li>Gaat het om een andere persoon op de polis? Kies dan voor "Andere wijziging".</li></ul><span class='mutation-buttons'>${linkOtherMutation}</span>`,
			active: true
		}
	},
	{
		mutationType: MutationType.BENEFICIARY,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.PDFUPLOAD,
		subject: "Begunstiging",
		title: "Begunstiging wijzigen",
		path: "begunstiging-wijzigen",
		taskTypeRequest: TaskType.WIJZ_BEGUNSTIGING,
		excludeForProducts: [
			ProductCode.ULLL,
			ProductCode.DIL,
			ProductCode.EXP
		],
		requiredDocuments: [
			documents.beneficiaryChangeForm,
			documents.identificationPolicyHolder,
			documents.identificationCoPolicyHolder,
			documents.bankStatementPolicyHolder,
			documents.bankStatementCoPolicyHolder
		],
		[Application.SELFSERVICE]: {
			introList: [
				"U kunt aanpassen wie de uitkering van de verzekering krijgt.",
				consequencesText,
				advisorText
			],
			paperForm: beneficiaryForm,
			active: true
		},
		[Application.EXTRANET]: {
			paperForm: beneficiaryForm,
			active: true
		}
	},
	{
		mutationType: MutationType.INVESTMENT_CHOICE,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.PDFUPLOAD,
		subject: "Beleggingskeuze",
		title: "Beleggingskeuze wijzigen",
		path: "beleggingskeuze-wijzigen",
		taskTypeRequest: TaskType.WIJZ_BELEGGINGSKEUZE,
		excludeForProducts: [
			ProductCode.DIL,
			ProductCode.EXP,
			ProductCode.NOM,
			ProductCode.ULVV,
			ProductCode.NOMWT,
			ProductCode.ORV,
			ProductCode.ORV1,
			ProductCode.SPH,
			ProductCode.VG
		],
		requiredDocuments: [
			documents.investmentChoiceForm
		],
		[Application.SELFSERVICE]: {
			introList: [
				"Wilt u uw beleggingsfondsen wijzigen?",
				consequencesText,
				requestIsFinalText,
				advisorText
			],
			introConditional: investmentChoiceConditionalIntro,
			paperForm: investmentChoiceForm,
			active: true
		},
		[Application.EXTRANET]: {
			paperForm: investmentChoiceForm,
			active: true
		}
	},
	{
		mutationType: MutationType.PAYMENT_TERM,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.DIGITAL,
		excludeForProducts: [
			ProductCode.DIL,
			ProductCode.EXP,
			ProductCode.VG
		],
		subject: "Betaaltermijn",
		title: "Betaaltermijn wijzigen",
		path: "betaaltermijn-wijzigen",
		taskTypeRequest: TaskType.WIJZ_BETAALTERMIJN,
		[Application.SELFSERVICE]: {
			introList: [
				"U kunt uw betaaltermijn wijzigen. U ontvangt dan een gewijzigde polis.",
				consequencesText,
				advisorText
			],
			active: true
		},
		[Application.EXTRANET]: {
			active: true
		}
	},
	{
		mutationType: MutationType.POSTPONE_ENDDATE,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.OFFER,
		subject: "Einddatum uitstellen",
		title: "Einddatum uitstellen",
		path: "einddatum-uitstellen",
		taskTypeRequest: TaskType.OFFERTE_DUUR,
		taskTypeApproved: TaskType.WIJZ_DUUR,
		excludeForProducts: [
			ProductCode.ULLL,
			ProductCode.DIL,
			ProductCode.EXP,
			ProductCode.HYB,
			ProductCode.HYB_AEG,
			ProductCode.NOM,
			ProductCode.ULVV,
			ProductCode.NOMWT,
			ProductCode.ORV,
			ProductCode.ORV1,
			ProductCode.SPH,
			ProductCode.VG
		],
		requiredDocuments: [
			documents.signedOffer
		],
		[Application.SELFSERVICE]: {
			introList: [
				"U kunt de einddatum van uw verzekering uitstellen.",
				"Het wijzigen van een einddatum kan grote gevolgen hebben.",
				advisorText
			],
			step1: "Vraag bij ons een offerte aan.",
			[OfferFase.REQUEST]: {
				introList: [
					"U wilt de einddatum van uw verzekering uitstellen. Vraag hier een offerte aan.",
					"Het wijzigen van een einddatum kan grote gevolgen hebben.",
					"De getekende offerte dient voor de huidige einddatum bij ons te worden aangeleverd.",
					advisorText
				],
				intro: "<strong>Let op!</strong> Wij verlengen een verzekering altijd zonder premiebetaling. Wilt u wel premie blijven betalen? Dan kunt u dit melden in het veld 'Opmerkingen'. Wij controleren dan of dit mogelijk is bij uw verzekering. En houden daar rekening mee in de offerte."
			},
			[OfferFase.APPROVED]: {
				intro: "U wilt de einddatum van uw verzekering uitstellen. Upload hieronder de ondertekende offerte."
			},
			active: true
		},
		[Application.EXTRANET]: {
			step1: "Vraag offerte aan voor het uitstellen van de einddatum van de polis.",
			[OfferFase.REQUEST]: {
				intro: "Hier kunt u een offerte aanvragen voor het uitstellen van de einddatum van de polis."
			},
			[OfferFase.APPROVED]: {
				intro: "Hier kunt u een wijziging voor het uitstellen van de einddatum van de polis doorgeven."
			},
			active: true
		}
	},
	{
		mutationType: MutationType.EXTRA_DEPOSIT,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.OFFER,
		subject: "Extra koopsom storten",
		title: "Extra koopsom storten",
		path: "extra-koopsom-storten",
		taskTypeRequest: TaskType.OFFERTE_EXTRASTORTING,
		taskTypeApproved: TaskType.WIJZ_EXTRASTORTING,
		excludeForProducts: [
			ProductCode.DIL,
			ProductCode.EXP,
			ProductCode.NOM,
			ProductCode.ULVV,
			ProductCode.NOMWT,
			ProductCode.ORV,
			ProductCode.ORV1,
			ProductCode.VG
		],
		requiredDocuments: [
			documents.signedOffer
		],
		[Application.SELFSERVICE]: {
			introList: [
				"Wilt u een extra koopsom storten van € 500 of meer?",
				"Deze extra koopsom kan grote gevolgen hebben.",
				advisorText
			],
			step1: "Vraag bij ons een offerte aan.",
			[OfferFase.REQUEST]: {
				introList: [
					"U wilt een extra koopsom storten van € 500 of meer. Vraag hier een offerte aan.",
					consequencesText,
					advisorText
				]
			},
			[OfferFase.APPROVED]: {
				intro: "U wilt een extra koopsom storten. Upload hieronder de ondertekende offerte."
			},
			active: true
		},
		[Application.EXTRANET]: {
			step1: "Vraag offerte aan voor een extra storting. Dit kan alleen bij een storting vanaf € 500 of hoger.",
			[OfferFase.REQUEST]: {
				intro: "Vraag offerte aan voor een extra storting. Dit kan alleen bij een storting vanaf € 500 of hoger."
			},
			[OfferFase.APPROVED]: {
				intro: "Upload hieronder de getekende offerte om een extra storting door te geven."
			},
			active: true
		}
	},
	{
		mutationType: MutationType.MORTGAGE_RATE,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.DIGITAL,
		subject: "Hypotheekrente wijziging",
		title: "Hypotheekrente wijziging - Wat wordt de premie?",
		path: "hypotheekrente-wijziging",
		taskTypeRequest: TaskType.PREMIE_BERHYPRENTE,
		excludeForProducts: [
			ProductCode.UL,
			ProductCode.UL_AEG,
			ProductCode.UL_AEG_ITG,
			ProductCode.ULAB,
			ProductCode.ULLL,
			ProductCode.DIL,
			ProductCode.EXP,
			ProductCode.NOM,
			ProductCode.ULVV,
			ProductCode.NOMWT,
			ProductCode.ORV,
			ProductCode.ORV1,
			ProductCode.VG
		],
		[Application.SELFSERVICE]: {
			introList: [
				"Heeft u een voorstel voor een nieuwe hypotheekrente ontvangen?",
				"Vraag hier alvast een premieberekening aan voor uw gekoppelde levensverzekering.",
				"Wij passen de premie pas aan zodra uw hypotheekverstrekker ons de gewijzigde rente doorgeeft.",
				`Deze wijziging kan grote gevolgen hebben. ${advisorText}`
			],
			active: true
		},
		[Application.EXTRANET]: {
			introList: [
				"Hieronder vraagt u alvast een premieberekening aan.",
				"Een wijziging van de hypotheekrente heeft gevolgen voor de premie van de gekoppelde levensverzekering.",
				"We passen de premie pas aan zodra de hypotheekverstrekker ons de gewijzigde rente doorgeeft."
			],
			active: true
		}
	},
	{
		mutationType: MutationType.NON_SMOKER_DISCOUNT,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.PDFUPLOAD,
		subject: "Niet-rokerskorting aanvragen",
		title: "Niet-rokerskorting aanvragen",
		path: "niet-rokerskorting-aanvragen",
		taskTypeRequest: TaskType.WIJZ_NON_SMOKERS,
		excludeForProducts: [
			ProductCode.ULLL,
			ProductCode.DIL,
			ProductCode.EXP
		],
		requiredDocuments: [
			documents.nonSmokersDiscountForm
		],
		[Application.SELFSERVICE]: {
			introList: [
				`Wilt u een “Niet-rokerskorting” aanvragen?`,
				consequencesText,
				advisorText
			],
			paperForm: nonSmokersDiscountForm,
			active: true
		},
		[Application.EXTRANET]: {
			paperForm: nonSmokersDiscountForm,
			active: true
		}
	},
	{
		mutationType: MutationType.PREMIUM_AMOUNT,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.OFFER,
		subject: "Premie",
		title: "Premie aanpassen",
		path: "premie-aanpassen",
		taskTypeRequest: TaskType.OFFERTE_PREMIE,
		taskTypeApproved: TaskType.WIJZ_PREMIE,
		excludeForProducts: [
			ProductCode.DIL,
			ProductCode.EXP,
			ProductCode.ORV,
			ProductCode.ORV1,
			ProductCode.VG
		],
		requiredDocuments: [
			documents.signedOffer
		],
		[Application.SELFSERVICE]: {
			introList: [
				"U wilt uw premie aanpassen.",
				"Het wijzigen van uw premie kan grote gevolgen hebben.",
				advisorText
			],
			step1: "Vraag bij ons een offerte aan.",
			[OfferFase.REQUEST]: {
				introList: [
					"U wilt uw premie aanpassen. Vraag hier een offerte aan.",
					consequencesText,
					advisorText
				]
			},
			[OfferFase.APPROVED]: {
				intro: "U wilt uw premie aanpassen. Upload hieronder de ondertekende offerte."
			},
			active: true
		},
		[Application.EXTRANET]: {
			step1: "Vraag offerte aan als u de premie wilt aanpassen.",
			[OfferFase.REQUEST]: {
				intro: "Verander de premie en vraag een offerte aan."
			},
			[OfferFase.APPROVED]: {
				intro: "Upload hier de ondertekende offerte om de premie te wijzigen."
			},
			active: true
		}
	},
	{
		mutationType: MutationType.PREMIUM_CONTRIBUTOR,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.OTHERPAGE,
		subject: "Premiebetaler",
		title: "Premiebetaler wijzigen",
		path: "premiebetaler",
		taskTypeRequest: TaskType.WIJZ_VERZEKERINGNEMER,
		excludeForProducts: [
			ProductCode.ULLL,
			ProductCode.DIL,
			ProductCode.EXP
		],
		[Application.SELFSERVICE]: {
			introList: [
				"U wilt de premiebetaler wijzigen. Dat betekent dat iemand anders de premie gaat betalen.",
				consequencesText,
				advisorText
			],
			intro: introPremiumContributor,
			active: true
		},
		[Application.EXTRANET]: {
			introList: [
				"U wilt de premiebetaler wijzigen. Dat betekent dat iemand anders de premie gaat betalen.",
				consequencesText,
				advisorText
			],
			intro: introPremiumContributor,
			active: false
		}
	},
	{
		mutationType: MutationType.END_PREMIUM,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.OFFER,
		subject: "Stoppen met premie betalen",
		title: "Stoppen met premie betalen",
		path: "stoppen-met-premie-betalen",
		taskTypeRequest: TaskType.OFFERTE_STOPPENPREMIE,
		taskTypeApproved: TaskType.WIJZ_STOPPENPREMIE,
		excludeForProducts: [
			ProductCode.DIL,
			ProductCode.EXP,
			ProductCode.ORV1,
			ProductCode.VG
		],
		requiredDocuments: [
			documents.signedOffer
		],
		[Application.SELFSERVICE]: {
			introList: [
				"U wilt geen premie meer betalen.",
				"Het stoppen van premie betalen kan grote gevolgen hebben.",
				advisorText
			],
			step1: "Vraag bij ons een offerte aan.",
			[OfferFase.REQUEST]: {
				introList: [
					"U wilt uw premie stopzetten. Vraag hier een offerte aan.",
					consequencesText,
					advisorText
				]
			},
			[OfferFase.APPROVED]: {
				intro: "U wilt uw premie stopzetten. Upload hieronder de ondertekende offerte."
			},
			active: true
		},
		[Application.EXTRANET]: {
			intro: "",
			step1: "Vraag offerte aan als u de verzekering premievrij wilt maken.",
			[OfferFase.REQUEST]: {
				intro: "Vraag hieronder een offerte aan om de verzekering premievrij te maken."
			},
			[OfferFase.APPROVED]: {
				intro: "Upload hier de ondertekende offerte om de verzekering premievrij te maken."
			},
			active: true
		}
	},
	{
		mutationType: MutationType.DELETE_INSURED,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.OFFER,
		subject: "Verzekerde verwijderen",
		title: "Verzekerde verwijderen",
		path: "verzekerde-verwijderen",
		taskTypeRequest: TaskType.OFFERTE_VERZEKERDEN,
		taskTypeApproved: TaskType.WIJZ_VERZEKERDEN,
		excludeForProducts: [
			ProductCode.ULLL,
			ProductCode.DIL,
			ProductCode.EXP,
			ProductCode.VG
		],
		requiredDocuments: [
			documents.signedOffer
		],
		[Application.SELFSERVICE]: {
			introList: [
				"U wilt een verzekerde verwijderen van de verzekering.",
				"Het verwijderen van een verzekerde kan grote gevolgen hebben.",
				advisorText
			],
			step1: "Vraag bij ons een offerte aan.",
			[OfferFase.REQUEST]: {
				introList: [
					"U wilt een verzekerde verwijderen van de verzekering.",
					"Het verwijderen van een verzekerde kan grote gevolgen hebben.",
					advisorText
				]
			},
			[OfferFase.APPROVED]: {
				intro: "U wilt een verzekerde verwijderen. Upload hieronder de ondertekende offerte."
			},
			active: true
		},
		[Application.EXTRANET]: {
			step1: "Vraag offerte aan om een verzekerde te verwijderen.",
			[OfferFase.REQUEST]: {
				intro: "Vraag hier een offerte aan om een verzekerde te verwijderen."
			},
			[OfferFase.APPROVED]: {
				intro: "Upload hieronder de ondertekende offerte. Dan verwijderen wij de verzekerde van de polis."
			},
			active: true
		}
	},
	{
		mutationType: MutationType.POLICYHOLDER,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.PDFUPLOAD,
		subject: "Verzekeringnemer",
		title: "Verzekeringnemer wijzigen",
		path: "verzekeringnemer-wijzigen",
		taskTypeRequest: TaskType.WIJZ_VERZEKERINGNEMER,
		excludeForProducts: [
			ProductCode.ULLL,
			ProductCode.DIL,
			ProductCode.EXP
		],
		requiredDocuments: [
			documents.policyHolderChangeForm,
			documents.identificationPolicyHolder,
			documents.identificationCoPolicyHolder,
			documents.bankStatementPolicyHolder,
			documents.bankStatementCoPolicyHolder,
			documents.identificationNewPolicyHolder,
			documents.bankStatementNewPolicyHolder
		],
		[Application.SELFSERVICE]: {
			introList: [
				"U wilt de verzekeringnemer wijzigen. Dat betekent dat iemand anders eigenaar wordt van de verzekering.",
				consequencesText,
				advisorText
			],
			paperForm: policyHolderForm,
			active: true
		},
		[Application.EXTRANET]: {
			intro: "",
			paperForm: policyHolderForm,
			active: true
		}
	},
	{
		mutationType: MutationType.END_INSURANCE,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.OTHERPAGE,
		subject: "Voortijdig stopzetten",
		title: "Voortijdig stopzetten",
		path: "voortijdig-stopzetten",
		excludeForProducts: [
			ProductCode.DIL,
			ProductCode.EXP
		],
		[Application.SELFSERVICE]: {
			intro: 'Dit kunt u regelen via "Uitkering". Ook als er geen afkoopwaarde is. Ga naar:<span class="mutation-buttons"><a id="linkToWidget" href="#">Uitkering</a></span>',
			active: true
		},
		[Application.EXTRANET]: {
			intro: 'Dit regelt u via de polisactie "Verzekering uitkeren". Ook als er geen afkoopwaarde is.',
			active: true
		}
	},
	{
		mutationType: MutationType.OTHER,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.DIGITAL,
		subject: "Andere wijziging",
		title: "Andere wijziging",
		path: "andere-wijziging",
		taskTypeRequest: TaskType.WIJZ_OVERIG,
		[Application.SELFSERVICE]: {
			introList: [
				"Wilt u iets wijzigen wat niet in de keuzelijst staat? Stel uw vraag dan in het tekstveld.",
				"U kunt ook een bijlage toevoegen.",
				"Een wijziging kan grote gevolgen hebben.",
				advisorText
			],
			active: true
		},
		[Application.EXTRANET]: {
			introList: [
				"Wilt u iets wijzigen wat niet in de keuzelijst staat? Gebruik dan het onderstaande tekstveld om uw vraag te stellen.",
				"U kunt ook bijlages toevoegen."
			],
			active: true
		}
	},
	{
		mutationType: MutationType.CONTACTFORM,
		mutationGroup: MutationGroup.CONTACTFORM,
		requestType: RequestType.DIGITAL,
		subject: "Contact",
		title: "Contact",
		path: "contactformulier",
		[Application.SELFSERVICE]: {
			intro: "Hieronder kunt u vragen stellen aan onze medewerkers.",
			active: true
		},
		[Application.EXTRANET]: {
			intro: "Hieronder kunt u vragen stellen aan onze medewerkers.",
			active: true
		}
	},
	{
		mutationType: MutationType.PAYOUT_BENEFICIARY_CONSENT,
		mutationGroup: MutationGroup.INSURANCE,
		requestType: RequestType.PDFUPLOAD,
		subject: "Uitkeren op einddatum - polishouder niet eerste begunstigde",
		title: "Uitkering van uw verzekering",
		path: "uitkeren-verzekering",
		taskTypeRequest: TaskType.WIJZ_BEGUNSTIGING,
		requiredDocuments: [
			documents.payoutBeneficiaryConsent,
			documents.identificationBeneficiary,
			documents.bankStatementBeneficiary
		],
		[Application.SELFSERVICE]: {
			intro: "U wilt uw verzekering graag laten uitbetalen. Wij keren alleen uit aan de begunstigde(n). De begunstiging van uw verzekering ziet u hieronder:",
			active: true
		},
		[Application.EXTRANET]: {

			active: false
		}
	}
]

export const getFormContentTexts = (formType: FormType, application: Application) => formContent[formType][application]

export const getMutationList = (application: Application, productCode: ProductCode, customerCountryCode?: string | null) => mutations.filter(mutation =>
	mutation[application].active
	&& (isUndefined(mutation.excludeForProducts) || productCode && !mutation.excludeForProducts.includes(productCode))
	&& (isUndefined(mutation.excludeForCountries) || customerCountryCode && !mutation.excludeForCountries.includes(customerCountryCode))
	&& mutation.mutationType !== MutationType.PAYOUT_BENEFICIARY_CONSENT
)

export const getContactFormSubjects = (application: Application) => contactFormSubjects[application]

export const getMutation = (mutationType: MutationType, productCode?: ProductCode, clauseCode?: ClauseCode, customerCountryCode?: string | null) => {
	const requestedMutation = mutations.find(mutation =>
		mutation.mutationType === mutationType
		&& (isUndefined(mutation.excludeForProducts) || productCode && !mutation.excludeForProducts.includes(productCode))
		&& (isUndefined(mutation.excludeForCountries) || customerCountryCode && !mutation.excludeForCountries.includes(customerCountryCode))
	) ?? null

	if (clauseCode && requestedMutation) {
		requestedMutation.SELFSERVICE.paperForm = getPayoutBeneficiaryConsentForm(clauseCode) || undefined
	}

	return requestedMutation
}
